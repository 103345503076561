import React from 'react'
import 'modern-normalize/modern-normalize.css'
import { Nav, UserNav } from '../Nav'
import '../../scss/styles.scss'

export function Layout({ children, isUserNav, page }) {
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      {isUserNav ? <UserNav page={page} /> : <Nav page={page} />}
      <div style={{display: 'flex', flex: 'auto', flexDirection: 'column'}}>{children}</div>
    </div>
  )
}

export function AppContent({ children }) {
  return (
    <div className="app-content-100" style={{ display: 'flex' }}>
      <div
        className="container"
        style={{ marginTop: 15, marginBottom: 15, flex: 'auto', display: 'flex', flexDirection: 'column' }}
      >
        {children}
      </div>
    </div>
  )
}
