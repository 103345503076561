const isBrowser = typeof window !== `undefined`

export const setUser = user =>
  (window.localStorage.authedUser = JSON.stringify(user))

const getUser = () => {
  if (window.localStorage.authedUser) {
    let user = JSON.parse(window.localStorage.authedUser)
    return user ? user : {}
  }
  return {}
}

export const isLoggedIn = () => {
  if (!isBrowser) return false

  const user = getUser()
  if (user) return !!user.username
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = callback => {
  if (!isBrowser) return
  setUser({})
  callback()
}

let challengeInfo

export const setChallengeInfo = info => (challengeInfo = info)

export const getChallengeInfo = () => challengeInfo
